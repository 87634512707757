import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'

import { PageTitle } from '../components/common'
import { ContentContainer, Row } from '../components/utils'
import { useGeneralData } from '../hooks'

const WidgetWrapper = styled.div`
  display: block;
  margin: 0 auto;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 480px;
  }
`

const Warning = styled.div`
  background-color: #fc5c65;
  color: #fff;
  text-align: center;
  padding: 8px 8px;
  border-radius: 3px;
  margin-bottom: 20px;
  margin: 0 auto 20px auto;
  max-width: 480px;
`

const BookPage = ({ location }) => {
  const { generalData } = useGeneralData()
  return (
    <Layout location={location}>
      <SEO title="Gift Vouchers" description="Make someone's day with a Hamilton Massage Company gift voucher." />
      <PageTitle title="Gift Vouchers" />
      <ContentContainer py="md">
        <Row col={1}>
          <div>
            <Warning>
              Please note when purchasing gift vouchers that there is usually a 4+ month wait for new appointments.
            </Warning>
            <WidgetWrapper>
              <iframe
                src={`//hamiltonmassagecompany.gettimely.com/giftvouchers/embed?"`}
                title="Hamilton Massage Company gift voucher widget"
                scrolling="no"
                id="timelyWidget"
                css={`
                  width: 100%;
                  height: 940px;
                  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
                  border-radius: 10px;
                  border: none;
                  padding: 20px;
                  margin-bottom: 40px;
                `}
              ></iframe>
            </WidgetWrapper>
          </div>
        </Row>
      </ContentContainer>
    </Layout>
  )
}

export default BookPage
